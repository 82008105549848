
					@import './src/sass/variables';
					@import './src/sass/mixins';
				












































































































.dropzone {
	min-height: 200px;

	form {
		height: 100%;
	}

	&.compact {
		min-height: 0;

		.file-upload > div {
			margin-bottom: 0;
			font-size: 0.8em;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			text-align: left;

			& > svg {
				margin-right: 10px;
				margin-bottom: 0;
			}
		}
	}
}

.file-upload {
	background: $color__white;
	padding: 10px 10px;
	height: 100%;
	position: relative;
	cursor: pointer;
	border-radius: $border_radius;
	box-shadow: $box_shadow__input;
	transition: 0.2s ease;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	svg {
		display: block;
		margin: 0 auto 0.25rem;
	}

	& > p,
	& > div {
		font-size: 1.2em;
		text-align: center;
		padding: 0;
		margin: 0 0 $default_padding / 2;
	}

	&:hover {
		box-shadow: $box_shadow;
	}

	.input-file {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}

.uploaded-files {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	& > :first-child {
		flex: 1 0 100%;
		margin-bottom: $default_padding / 2;
	}

	& > div {
		padding: $default_padding / 2;
		flex: 1 0 200px;
		max-width: 200px;
	}

	small {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	svg {
		display: block;
		margin: 0 auto $default_padding / 2;
	}
}
