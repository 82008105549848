
					@import './src/sass/variables';
					@import './src/sass/mixins';
				















































































































header {
	margin-bottom: $default_padding * 0.5;
}

.select-input {
	flex: 1;
	text-align: center;
	font-size: 1.25em;
	line-height: 1.5em;

	span {
		font-weight: $font__semibold;
		cursor: pointer;
	}
}

.input-group::v-deep {
	.dropzone,
	input {
		margin-bottom: 0;
		flex: 1;
	}

	.input-group-row {
		.button {
			align-self: center;
		}
	}
}

.input-group {
	display: flex;
	flex-flow: column nowrap;

	.input-group-row {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 10px;
		column-gap: 20px;
	}

	.input-group-col {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		flex: 1;
		column-gap: 10px;
		border: 2px dashed $border_color;
		padding: 5px;
		border-radius: $border_radius;
	}

	& > .button {
		align-self: center;
	}

	button {
		background-color: $color__dark;

		&:hover {
			background-color: rgba($color: $color__dark, $alpha: 0.8);
		}
	}
}
